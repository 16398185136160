<template>
    <div>
        <div class="table-element">
            <el-table :data="tableData" height="60vh">
                <el-table-column label="Payment ID" prop="payment_id">
                </el-table-column>
                <el-table-column label="Payable Amount" prop="payable_amount"></el-table-column>
                <el-table-column label="Paid Amount" prop="paid_amount"></el-table-column>
                <el-table-column label="Created By">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column label="Created At" prop="date"></el-table-column>
                <el-table-column label="Action">
                    <template slot-scope="scope">
                        <div>
                            <button class="delete-icon" @click="openModal({ row: scope.row, key: scope.$index })"><svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M17.4185 3.30531C17.8087 3.30531 18.1335 3.62537 18.1335 4.03263V4.40918C18.1335 4.80653 17.8087 5.1365 17.4185 5.1365H0.796052C0.4049 5.1365 0.0800781 4.80653 0.0800781 4.40918V4.03263C0.0800781 3.62537 0.4049 3.30531 0.796052 3.30531H3.72042C4.31447 3.30531 4.83145 2.88813 4.96509 2.29954L5.11823 1.62374C5.35624 0.703186 6.13952 0.0917969 7.03595 0.0917969H11.1777C12.0643 0.0917969 12.8564 0.703186 13.0856 1.57518L13.2495 2.29855C13.3822 2.88813 13.8992 3.30531 14.4942 3.30531H17.4185ZM15.9329 17.0702C16.2382 14.259 16.7727 7.58027 16.7727 7.51289C16.7922 7.30877 16.7249 7.11554 16.5913 6.95997C16.4479 6.8143 16.2665 6.7281 16.0665 6.7281H2.15474C1.9538 6.7281 1.76262 6.8143 1.62996 6.95997C1.49535 7.11554 1.42902 7.30877 1.43877 7.51289C1.44056 7.52527 1.45974 7.76053 1.49181 8.15384C1.63426 9.90107 2.03102 14.7674 2.2874 17.0702C2.46884 18.7666 3.59547 19.8328 5.22738 19.8715C6.48668 19.9002 7.78402 19.9101 9.11062 19.9101C10.3602 19.9101 11.6292 19.9002 12.9275 19.8715C14.616 19.8427 15.7417 18.7953 15.9329 17.0702Z"
                                    fill="#D4D8E2" class="delete-icon" />
                            </svg></button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="col-md-12" style="text-align: center; margin-top:1em;">
                <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="pageSize"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="delete-confirmation">
            <el-dialog title="Delete Payment" :visible.sync="dialogVisible">
                <span>Are you sure you want to delete this payment?</span>
                <span class="details">
                    <p style="font-size:11px">Payment ID: &nbsp; <strong style="font-size:14px"> {{
                        specificPayment.payment_id
                    }}</strong></p>
                    <p style="font-size:11px">Payable Amount: &nbsp; <strong style="font-size:14px">{{
                        specificPayment.payable_amount
                    }}</strong></p>
                    <p style="font-size:11px">Paid Amount: &nbsp; <strong style="font-size:14px">{{
                        specificPayment.paid_amount
                    }}</strong></p>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">Cancel</el-button>
                    <el-button type="danger" @click="confirmDeletionMethod" :loading="buttonLoading">Delete</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import store from '@/state/store.js'
export default {
    data() {
        return {
            buttonLoading: false,
            dialogVisible: false,
            specificPayment: {},
            page: 1,
            pageSize: 20,
            total: 0,
        }
    },
    computed: {
        tableData() {
            return store.getters['admin/reporting/specificDebtor'].debtors_details
            
        },
    },
    methods: {
        openModal({ row }) {
            this.dialogVisible = true
            this.specificPayment = row
        },
        confirmDeletionMethod() {
            this.buttonLoading = true
            store.dispatch('admin/reporting/deleteSpecificDebtor', {
                id: this.$route.params.id,
                payment_id: this.specificPayment.payment_id
            }).then(() => {
                this.buttonLoading = false
                this.dialogVisible = false
                // Refetch data
                store.dispatch('admin/reporting/getSpecificDebtor', this.$route.params.id)
            })
        },
        handleCurrentChange(val) {
            this.page = val;
        },
    },
    beforeMount() {
        store.dispatch('admin/reporting/getSpecificDebtor', this.$route.params.id)
    }
}
</script>
<style scoped>
.delete-icon{
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
 }

 .delete-icon:hover svg path {
     fill: red;
     transition: .5s;
 }
</style>